export type COLORS_TYPE = {
  Auburn: string;
  Black: string;
  Blonde: string;
  BlondeGolden: string;
  Brown: string;
  BrownDark: string;
  PastelPink: string;
  Blue: string;
  Platinum: string;
  Red: string;
  SilverGray: string;
  Blue01: string;
  Blue02: string;
  Blue03: string;
  Gray01: string;
  Gray02: string;
  Heather: string;
  PastelBlue: string;
  PastelGreen: string;
  PastelOrange: string;
  PastelRed: string;
  PastelYellow: string;
  Pink: string;
  White: string;
};

export const COLORS: COLORS_TYPE = {
  Auburn: "#A65728",
  Black: "#2C1B18",
  Blonde: "#B58142",
  BlondeGolden: "#D6B370",
  Brown: "#714133",
  BrownDark: "#49312C",
  PastelPink: "#F69696",
  Blue: "#0B10DB",
  Platinum: "#EBDDBF",
  Red: "#C93305",
  SilverGray: "#E8E1E1",
  Blue01: "#66C9FF",
  Blue02: "#5199E4",
  Blue03: "#25557C",
  Gray01: "#E6E6E6",
  Gray02: "#929598",
  Heather: "#3C4E5C",
  PastelBlue: "#B1E3FF",
  PastelGreen: "#A7FFC4",
  PastelOrange: "#FFDEB5",
  PastelRed: "#FFAEB9",
  PastelYellow: "#FEFFB1",
  Pink: "#FF488D",
  White: "#FFFFFF",
};

export const PARTS = [
  { id: 1, name: "top type", part: "topType" },
  { id: 2, name: "accessories type", part: "accessoriesType" },
  { id: 3, name: "facial hair type", part: "facialHairType" },
  { id: 4, name: "clothe type", part: "clotheType" },
  { id: 5, name: "eye type", part: "eyeType" },
  { id: 6, name: "eyebrow type", part: "eyebrowType" },
  { id: 7, name: "mouth type", part: "mouthType" },
  { id: 8, name: "skin color", part: "skinColor" },
  { id: 9, name: "font color", part: "fontColor" },
];

export const hairColor = {
  Auburn: "#A65728",
  Black: "#2C1B18",
  Blonde: "#B58142",
  BlondeGolden: "#D6B370",
  Brown: "#714133",
  BrownDark: "#49312C",
  PastelPink: "#F69696",
  Blue: "#0B10DB",
  Platinum: "#EBDDBF",
  Red: "#C93305",
  SilverGray: "#E8E1E1",
};

export const hatColor = {
  Black: "#2C1B18",
  Blue01: "#66C9FF",
  Blue02: "#5199E4",
  Blue03: "#25557C",
  Gray01: "#E6E6E6",
  Gray02: "#929598",
  Heather: "#3C4E5C",
  PastelBlue: "#B1E3FF",
  PastelGreen: "#A7FFC4",
  PastelOrange: "#FFDEB5",
  PastelRed: "#FFAEB9",
  PastelYellow: "#FEFFB1",
  Pink: "#FF488D",
  Red: "#C93305",
  White: "#FFFFFF",
};

export const colors = {
  hairColor: {
    Auburn: "#A65728",
    Black: "#2C1B18",
    Blonde: "#B58142",
    BlondeGolden: "#D6B370",
    Brown: "#714133",
    BrownDark: "#49312C",
    PastelPink: "#F69696",
    Blue: "#0B10DB",
    Platinum: "#EBDDBF",
    Red: "#C93305",
    SilverGray: "#E8E1E1",
  },
  hatColor: {
    Black: "#2C1B18",
    Blue01: "#66C9FF",
    Blue02: "#5199E4",
    Blue03: "#25557C",
    Gray01: "#E6E6E6",
    Gray02: "#929598",
    Heather: "#3C4E5C",
    PastelBlue: "#B1E3FF",
    PastelGreen: "#A7FFC4",
    PastelOrange: "#FFDEB5",
    PastelRed: "#FFAEB9",
    PastelYellow: "#FEFFB1",
    Pink: "#FF488D",
    Red: "#C93305",
    White: "#FFFFFF",
  },
  facialHairColor: {
    Auburn: "#A65728",
    Black: "#2C1B18",
    Blonde: "#B58142",
    BlondeGolden: "#D6B370",
    Brown: "#714133",
    BrownDark: "#49312C",
    Platinum: "#EBDDBF",
    Red: "#C93305",
  },
  clotheColor: {
    Black: "#2C1B18",
    Blue01: "#66C9FF",
    Blue02: "#5199E4",
    Blue03: "#25557C",
    Gray01: "#E6E6E6",
    Gray02: "#929598",
    Heather: "#3C4E5C",
    PastelBlue: "#B1E3FF",
    PastelGreen: "#A7FFC4",
    PastelOrange: "#FFDEB5",
    PastelRed: "#FFAEB9",
    PastelYellow: "#FEFFB1",
    Pink: "#FF488D",
    Red: "#C93305",
    White: "#FFFFFF",
  },
};

export const colorsBg = [
  { name: "Black", color: "#2C1B18" },
  { name: "Blue01", color: "#66C9FF" },
  { name: "Blue02", color: "#5199E4" },
  { name: "Blue03", color: "#25557C" },
  { name: "Gray01", color: "#E6E6E6" },
  { name: "Gray02", color: "#929598" },
  { name: "Heather", color: "#3C4E5C" },
  { name: "PastelBlue", color: "#B1E3FF" },
  { name: "PastelGreen", color: "#A7FFC4" },
  { name: "PastelOrange", color: "#FFDEB5" },
  { name: "PastelRed", color: "#FFAEB9" },
  { name: "PastelYellow", color: "#FEFFB1" },
  { name: "Pink", color: "#FF488D" },
  { name: "Red", color: "#C93305" },
  { name: "White", color: "#FFFFFF" },
];

// 14 with graphicType
export const avatarConfig = {
  topType: "LongHairStraight",
  accessoriesType: "Blank",
  clotheColor: "undefined",
  clotheType: "BlazerShirt",
  eyeType: "Default",
  eyebrowType: "Default",
  facialHairColor: "undefined",
  facialHairType: "Blank",
  skinColor: "Light",
  hairColor: "BrownDark",
  hatColor: "undefined",
  mouthType: "Default",
  fontColor: "#66C9FF",
};

// add later graphicType: "undefined",
