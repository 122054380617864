import { FC } from "react";

import Button from "../../shared/Button";

import { PARTS } from "../../utils/constants";

import classes from "./PartSelector.module.scss";
import { ITranslationKeys } from "../../services/localeService";

export interface IPartSelector {
  currentPart: string;
  handleCurrentPart: (slug: string) => void;
  currentBodyPart: string;
  getLocaleText: (key: any) => string;
}

export const PartSelector: FC<IPartSelector> = ({
  currentPart,
  handleCurrentPart,
  currentBodyPart,
  getLocaleText,
}) => {

  const getPartsArray = () => {
    if (!PARTS) return [];
    if (currentBodyPart === "Eyepatch") {
      return PARTS.filter(({ part }) => part !== "accessoriesType");
    }
    return PARTS;
  };

  return (
    <div className={classes["part-selector"]}>
      {getPartsArray().map(({ name, part }) => (
        <Button
          key={name}
          text={getLocaleText(name)}
          onClick={() => handleCurrentPart(part)}
          isActive={currentPart === part}
        />
      ))}
    </div>
  );
};
