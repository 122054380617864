import React from "react";
import cn from "classnames";

import classes from "./BodyPart.module.scss";

import lockSvg from "../../static/icons/lock.svg";
import coinSvg from "../../static/icons/coin.svg";

export interface IBodyPartProps {
  type: string;
  currentPart: string;
  isActive?: boolean;
  image: string;
  onClick: (value: string) => void;
}

export const BodyPart: React.FC<IBodyPartProps> = ({
  type,
  currentPart,
  isActive,
  image,
  onClick,
}) => {
  return (
    <button
      className={cn(
        classes["body-part"],
        isActive && classes["body-part-active"]
      )}
      onClick={() => onClick(type)}
    >
      {/*<div className={classes["body-part-unlocked"]}>*/}
      {/*  <img src={lockSvg} alt="Locked" />*/}
      {/*  <div className={classes["body-part-locked-cost"]}>*/}
      {/*    <img src={coinSvg} alt="Coins" />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <img src={image} alt={type} className={cn(classes["body-part-avatar"])} />
    </button>
  );
};
