import { api } from "../config/axiosConfig";

export const getAvatar = (token: string) => {
  return api.get("/auth/me", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const updateAvatar = (bodyFormData: FormData, token: string) => {
  bodyFormData.delete("avatarStyle");

  return api.put("/avatar/update", bodyFormData, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const createAvatar = (bodyFormData: FormData, token: string) => {
  bodyFormData.delete("avatarStyle");

  return api.post("/avatar", bodyFormData, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getCurrentAvatar = (token: string) => {
  return api.get("/avatar", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getAvatarParts = async (
  token: string,
  queryParams?: {
    order?: string;
    page?: number;
    take?: number;
    q?: string;
    elementsType?: string;
    option: "CUSTOMIZE";
  }
) => {

  return  api.get("/avatar-element/all", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: queryParams,
  });
}


export const getAvatarPart = async (
  token: string,
  queryParams: {
    order?: string;
    page?: number;
    take?: number;
    q?: string;
    elementsType?: string;
    option: "CUSTOMIZE";
  }
) => {

  return api.get("/avatar-element", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: queryParams,
  });
};
