

export type ITranslationKeys = {
    'Your avatar' : string
    'Done' : string
    'top type' : string
    "accessories type": string,
    "facial hair type": string,
    "clothe type": string,
    "eye type": string,
    "eyebrow type": string,
    "mouth type": string,
    "skin color": string,
    "font color": string
}

const en: ITranslationKeys  = {
    'Your avatar': 'Your avatar',
    'Done': 'Done',
    'top type' : 'top type',
    "accessories type":  "accessories type",
    "facial hair type": "facial hair type",
    "clothe type": "clothe type",
    "eye type": "eye type",
    "eyebrow type": "eyebrow type",
    "mouth type":  "mouth type",
    "skin color": "skin color",
    "font color": "font color"
}
const ru: ITranslationKeys  = {
    'Your avatar': 'Ваш аватар',
    "top type": "Верх",
    "accessories type": "Аксессуары",
    "facial hair type": "Бороды",
    "clothe type": "Одежда",
    "eye type": "Глаза",
    "eyebrow type": "Брови",
    "mouth type": "Эмоции",
    "skin color": "Кожа",
    "font color": "Фон",
    Done: "Готово"
}

function getTranslate(key: keyof ITranslationKeys, lang?: string): string
{
    if(lang === 'ru') {
        return ru[key] || key;
    }

    return en[key] || key;
}

export  {
    getTranslate
}