import React, { useEffect, useState } from "react";
import { Avatar, AvatarStyle, allOptions } from "avataaars";
import cn from "classnames";
import {
  UrlQueryParamTypes,
  UrlUpdateTypes,
  addUrlProps,
} from "react-url-query";
import fromPairs from "lodash.frompairs";

import Header from "../Header";
import PartSelector from "../PartSelector";
import ColorSelector from "../ColorSelector";
import BodyPartSelector from "../BodyPartSelector";
import BackgroundSelector from "../BackgroundSelector";
import Loader from "../../shared/Loader";

import { colors } from "../../utils/constants";
import withAvatarGenerator from "../../HOCS/withAvatarGenerator";

import classes from "./AvatarGenerator.module.scss";
import "react-notifications/lib/notifications.css";

const updateType = UrlUpdateTypes.pushIn;

const urlPropsQueryConfig = {
  ...fromPairs(
    allOptions.map((option) => [
      option.key,
      {
        type: UrlQueryParamTypes.string,
        updateType,
      },
    ])
  ),
  avatarStyle: {
    type: UrlQueryParamTypes.string,
    updateType,
  },
};

const excludedParts = ["eyeType", "mouthType"];

const App: React.FC = ({
  onRandom,
  onAvatarRef,
  optionContext,
  onDownloadPNG,
  onCanvasRef,
  bodyPartsLoading,
   getLocaleText,
   isAvatarCreated,
  bodyPartTypes,
  currentPart,
  handleCurrentPart,
}: any) => {
  const [partColors, setPartColors] = useState<any>(null);
  const [currentColorPart, setCurrentColorPart] = useState<string | null>(null);
  const [colorSelector, setColorSelector] = useState<boolean>(false);
  const [extraColorBackground, setExtraColorBackground] =
    useState<string>("BrownDark");
  const [isCurrentAvatarLoading, setIsCurrentAvatarLoading] =
    useState<boolean>(false);

  const includedColors = {
    topType: ["hatColor", "hairColor"],
    clotheType: "clotheColor",
    facialHairType: "facialHairColor",
  };

  const AVATAR_STYLE = AvatarStyle.Circle;
  const onHandleDownloadPNG = () => {

    onDownloadPNG()

  }
  const getTopTypeColors = () => {
    const isHatColor = optionContext.getOptionState("hatColor")?.available;
    const isHairColor = optionContext.getOptionState("hairColor")?.available;

    if (isHatColor || isHairColor) {
      if (isHatColor) {
        const color = "hatColor";
        setPartColors(colors[color]);
        setCurrentColorPart(color);
      } else {
        const color = "hairColor";
        setPartColors(colors[color]);
        setCurrentColorPart(color);
      }
    } else {
      setPartColors(null);
      setCurrentColorPart(null);
    }
  };

  useEffect(() => {
    const color = includedColors[currentPart as keyof typeof includedColors];
    if (color) {
      if (Array.isArray(color)) {
        getTopTypeColors();
      } else {
        setPartColors(colors[color as keyof typeof colors]);
        setCurrentColorPart(color);
      }
    } else {
      setPartColors(null);
      setCurrentColorPart(null);
    }
  }, [currentPart, optionContext.getValue(currentPart)]);

  useEffect(() => {
    const existedExcludedParts = !excludedParts.some(
      (part) => part === currentPart
    );

    setColorSelector(existedExcludedParts);
  }, [currentPart]);

  const handleChangeColor = (color: string) => {
    if (currentColorPart) {
      optionContext.setValue(currentColorPart, color);
      setExtraColorBackground(color);
    }
  };

  const colorSelectorComponent = colorSelector && (
    <ColorSelector
      currentColor={optionContext.getValue(currentColorPart)}
      partColors={partColors}
      handleChangeColor={handleChangeColor}
    />
  );

  return (
    <div className={classes["app"]}>
      {bodyPartsLoading && (
        <div className={classes["app-loader-full"]}>
          <Loader />
        </div>
      )}
      <Header handleRandom={onRandom} getLocaleText={getLocaleText} onDownloadPNG={onHandleDownloadPNG} />
      <div
        className={cn(
          classes["app-container"],
         // !isAvatarCreated && classes["app-filter"]
        )}
      >
        <Avatar
          ref={onAvatarRef}
          avatarStyle={AVATAR_STYLE}
          className={classes["app-avatar"]}
        />
      </div>
      <PartSelector
        currentPart={currentPart}
        handleCurrentPart={handleCurrentPart}
        getLocaleText={getLocaleText}
        currentBodyPart={optionContext.getValue(currentPart)}
      />

      {colorSelectorComponent}

      {/*{bodyPartsLoading && (*/}
      {/*  <div className={classes["app-loader"]}>*/}
      {/*    <Loader />*/}
      {/*  </div>*/}
      {/*)}*/}

      {!bodyPartsLoading &&
        (currentPart !== "fontColor" ? (
          <BodyPartSelector
            currentPart={currentPart}
            optionContext={optionContext}
            bodyPartTypes={bodyPartTypes}
          />
        ) : (
          <BackgroundSelector currentColor={extraColorBackground} />
        ))}

      <canvas
        style={{ display: "none" }}
        width="528"
        height="560"
        ref={onCanvasRef}
      />
    </div>
  );
};

export default addUrlProps({ urlPropsQueryConfig })(withAvatarGenerator(App));
