import { OptionContext } from "avataaars";

export const useGetBodyPartSelector = (
  optionContext: OptionContext,
  currentPart: string
) => {
  const optionState = optionContext.getOptionState(currentPart)!;

  if (optionState.available <= 0) {
    return { selectedPart: "" };
  }


  const selectedPart = optionContext.getValue(currentPart);


  return { selectedPart };
};
