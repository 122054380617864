import * as React from "react";

import AvatarGenerator from "../AvatarGenerator";
import history from "../../history";

export default class App extends React.Component {
  componentDidMount() {
    history.listen(() => this.forceUpdate());
  }

  render() {

    return <AvatarGenerator />;
  }
}
