import { avatarConfig } from "./constants";

export const getPropertiesUrl = () => {
  const search = window.location.search;
  const query = new URLSearchParams(search);

  const entries = query.entries();

  let propertiesObj = { ...avatarConfig };

  for (const [key, value] of entries) {
    propertiesObj = { ...propertiesObj, [key]: value };
  }

  return propertiesObj;
};
