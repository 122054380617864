import React from "react";
import cn from "classnames";

import clasees from "./Button.module.scss";

interface IButtonProps {
  text: string;
  className?: string;
  isActive?: boolean;
  onClick: () => void;
}

export const Button: React.FC<IButtonProps> = ({ text, onClick, isActive }) => (
  <button
    className={cn(clasees["btn"], isActive && clasees["btn-active"])}
    onClick={() => onClick()}
  >
    {text}
  </button>
);
