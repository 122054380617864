import React from "react";
import cn from "classnames";

import classes from "./ColorCircle.module.scss";

export interface IColorCircleProps {
  color: string;
  onClick: () => void;
  isActive?: boolean;
}

export const ColorCircle: React.FC<IColorCircleProps> = ({
  onClick,
  color,
  isActive,
}) => (
  <button
    onClick={onClick}
    className={cn(
      classes["color-circle"],
      isActive && classes["color-circle-active"]
    )}
    style={{ backgroundColor: color }}
  />
);
