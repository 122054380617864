import { FC } from "react";

import ColorCircle from "../../shared/ColorCircle";

import classes from "./ColorSelector.module.scss";

export interface IColorSelectorProps {
  currentColor: string;
  partColors: any;
  handleChangeColor: (color: string) => void;
}

export const ColorSelector: FC<IColorSelectorProps> = ({
  currentColor,
  partColors,
  handleChangeColor,
}) => {
  if (!partColors) return null;

  return (
    <div className={classes["color-selector"]}>
      {Object.keys(partColors).map((color) => (
        <ColorCircle
          key={color}
          onClick={() => handleChangeColor(color)}
          color={partColors[color as keyof typeof partColors]}
          isActive={currentColor === color}
        />
      ))}
    </div>
  );
};

export default ColorSelector;
