import React, { useRef } from "react";
import { OptionContext } from "avataaars";

import BodyPart from "../../shared/BodyPart";

import { useGetBodyPartSelector } from "./useGetBodyPartSelector";

import classes from "./BodyPartSelector.module.scss";

interface IBodyPartSelectorProps {
  optionContext: OptionContext;
  currentPart: string;
  bodyPartTypes: IBodyPartTypes[];
}

interface BodyPartSelectorProps {
  selectedPart: any;
}

interface IBodyPartTypes {
  id: string;
  image: string;
  type: string;
  price: string;
  isLocked?: boolean;
}

export const BodyPartSelector: React.FC<IBodyPartSelectorProps> = ({
  optionContext,
  currentPart,
  bodyPartTypes,
}) => {
  const { selectedPart }: BodyPartSelectorProps = useGetBodyPartSelector(
    optionContext,
    currentPart
  );

  const bodyPartSelectorRef = useRef<HTMLDivElement | null>(null);

  const handleBodyPart = (value: string) => {
    optionContext.setValue(currentPart, value);
  };

  return (
    <div className={classes["body-part-selector"]} ref={bodyPartSelectorRef}>
      {bodyPartTypes.map(({ id, image, isLocked, type, price }) => (
        <BodyPart
          key={id}
          type={type}
          image={image}
          currentPart={currentPart}
          isActive={type === selectedPart}
         // isLocked={isLocked}
         // price={price}
          onClick={handleBodyPart}
        />
      ))}
    </div>
  );
};
