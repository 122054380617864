import React, { FC } from "react";

import randomSvg from "../../static/icons/random.svg";

import classes from "./Header.module.scss";
import {ITranslationKeys} from "../../services/localeService";

export interface IHeaderProps {
  handleRandom: () => void;
  onDownloadPNG: any;
  getLocaleText: (key: keyof ITranslationKeys) => string;
}

export const Header: FC<IHeaderProps> = ({ handleRandom, onDownloadPNG, getLocaleText }) => (
  <header className={classes["header"]}>
    <button onClick={handleRandom} className={classes["header-btn-rnd"]}>
      <img src={randomSvg} alt="Randomly" />
    </button>
    <h1 className={classes["header-title"]}>{getLocaleText('Your avatar')}</h1>
    <button onClick={onDownloadPNG} className={classes["header-btn-done"]}>
        {getLocaleText('Done')}
    </button>
  </header>
);
