import React from "react";
import { Oval } from "react-loader-spinner";

interface ILoaderProps {
  height?: number;
  width?: number;
  color?: string;
}

export const Loader: React.FC<ILoaderProps> = (config: ILoaderProps) => {
  const { height, width, color } = config;
  return (
    <Oval
      height={height || 50}
      width={width || 50}
      color={color || "#EF5A3C"}
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor="#EF5A3C"
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  );
};
