import React from "react";
import { Avatar, Option, OptionContext, allOptions } from "avataaars";
import sample from "lodash.sample";
import * as PropTypes from "prop-types";
import * as ReactDOM from "react-dom";

import {
  getAvatar,
  updateAvatar,
  createAvatar,
  getAvatarPart, getAvatarParts,
} from "../../services/avatarApi";

import { UrlUpdateTypes } from "react-url-query";

import { IAppProps, IAppState } from "../../utils/types";
import { getPropertiesUrl } from "../../utils/getPropertiesUrl";
import { PARTS } from "../../utils/constants";
import { logDOM } from '@testing-library/react';
import {getTranslate, ITranslationKeys} from "../../services/localeService";

//     optionContext.setValue("clotheType", "ShirtCrewNeck");

export const withAvatarGenerator = (
  WrappedComponent: React.ComponentType<IAppProps>
) => {
  return class App extends React.Component<IAppProps, IAppState> {
    static childContextTypes = {
      optionContext: PropTypes.instanceOf(OptionContext),
    };

    state: IAppState = {
      isAvatarCreated: false,
      bodyPartsLoading: false,
      currentPart: "topType",
      bodyPartTypes: [],
      token: "",
      locale: "",
      storage: {},
    };

    private optionContext: OptionContext = new OptionContext(allOptions);
    private avatarRef: Avatar | null = null;
    private canvasRef: HTMLCanvasElement | null = null;
    private token: any;
    public locale: string | null = null;

    getChildContext() {
      return { optionContext: this.optionContext };
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
      this.updateOptionContext(nextProps);
    }

    UNSAFE_componentWillMount() {

      this.optionContext.addValueChangeListener(this.onOptionValueChange);
      this.updateOptionContext(this.props);
    }

    async componentDidMount() {
      try {

        const token = await this.getToken();
        if (token && token.length > 0) {
          getAvatar(token)
            .then((res) => {

              const { data } = res;
              const { avatar, avatarConfig = {},  languageCode = 'en' } = data;


              if (avatar) {

                if(avatarConfig) {
                  Object.keys(avatarConfig)
                    .filter(key => avatarConfig[key] && avatarConfig[key] !=='undefined')
                    .forEach((key) =>
                    this.onOptionValueChange(key, avatarConfig[key])
                  )
                }
                //
                // if(avatarConfig) {
                //   this.updateOptionContext(avatarConfig);
                // }

                this.setState({
                  isAvatarCreated: true,
                  token,
                  locale: languageCode.toLowerCase(),
                  avatarConfig
                });
                return
              }
              this.setState({
                locale: languageCode.toLowerCase(),
              });
            })
            .then(() => {
              const fetchAllAvatarParts = async () => {
                this.setState((prevState) => ({
                  ...prevState,
                  bodyPartsLoading: true,
                }));
                const response = await getAvatarParts(token, );

                const allParts = response?.data || {};
                let storage: any = {};


                for  (let partItem of PARTS) {

                  try {
                    if(allParts[partItem.part]) {
                      storage[partItem.part] = { data: allParts[partItem.part] };
                      continue;
                    }

                    const response = await getAvatarPart(token, {
                      elementsType: this.capitalizeFirstLetter(partItem.part),
                      option: "CUSTOMIZE",
                    });

                    if (response.data) {
                      storage[partItem.part] = response.data;
                    }
                  } catch (error) {
                    this.scanBarcode(`error ${error ? error.toString() : 'unknown '}`);
                    console.error("Error fetching", partItem.part, ":", error);
                  }
                }

                this.setState((prevState) => ({
                  ...prevState,
                  bodyPartTypes: storage["topType"].data,
                  storage,

                  bodyPartsLoading: false,
                }));
              };

              fetchAllAvatarParts();
            });
        }
      } catch (err) {
        console.error(err);

        this.setState((prevState) => ({
          ...prevState,
          bodyPartsLoading: false,
        }));
      }
    }

    componentDidUpdate(
      prevProps: Readonly<IAppProps>,
      prevState: Readonly<IAppState>,
      snapshot?: any
    ): void {
      if (prevState.currentPart !== this.state.currentPart) {
        const { storage, currentPart } = this.state;

        const bodyPartTypes = storage[currentPart];

        if(!bodyPartTypes) {
          return
        }

        this.setState((prevState) => ({
          ...prevState,
          bodyPartTypes: bodyPartTypes.data,
        }));
      }
    }

    componentWillUnmount() {
      this.setState({
        isAvatarCreated: false,
      });

      this.optionContext.removeValueChangeListener(this.onOptionValueChange);
    }

    private onAvatarRef = (ref: Avatar) => {
      this.avatarRef = ref;
    };

    private onRandom = () => {
      const { optionContext } = this;
      const { storage } = this.state;

      const values: { [index: string]: string } = {
        avatarStyle: "Circle",
      };

      for (const option of optionContext.options) {
        if (option.key in values) {
          continue;
        }

        const optionState = optionContext.getOptionState(option.key)!;

        if (!optionState.options.length) {
          continue;
        }

        const stateOptions = optionState.options.filter((item: any) => {
          if (!storage[option.key] || !storage[option.key].data) return;

          if (
            storage[option.key].data ||
            Object.keys(storage[option.key].data).length > 0
          ) {
            return storage[option.key].data.some(
              (filterItem: any) => filterItem.type === item
            );
          }
        });

        values[option.key] = sample(stateOptions)!;
      }
      this.optionContext.setData(values);
      this.forceUpdate();

      this.props.onChangeUrlQueryParams(values, UrlUpdateTypes.push);
    };

    private svgToPngUrl = (
      data: string,
      ctx: CanvasRenderingContext2D,
      canvas: HTMLCanvasElement
    ): Promise<string> => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        const url =
          "data:image/svg+xml;charset=utf-8," + encodeURIComponent(data);

        img.onerror = (err) => {
          console.error("Error loading image:", err);
          reject(err);
        };

        img.onload = () => {
          ctx.save();
          ctx.scale(2, 2);
          ctx.drawImage(img, 0, 0);
          ctx.restore();
          const pngUrl = canvas.toDataURL("image/png");
          resolve(pngUrl);
        };

        img.src = url;
      });
    };

    private onDownloadPNG = async () => {

      const svgNode = ReactDOM.findDOMNode(this.avatarRef!)! as Element;
      const canvas = this.canvasRef!;
      const ctx = canvas.getContext("2d")!;
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const data = svgNode.outerHTML;


      const pngUrl = await this.svgToPngUrl(data, ctx, canvas);

      const token = await this.getToken();

      if (!pngUrl || !token) {
        this.scanBarcode("success");
        return;
      }


      const pngBlob = await this.fetchPngBlob(pngUrl);

      const bodyFormData = this.prepareFormData(pngBlob);

      const isAvatarCreated = this.state.isAvatarCreated;
      this.setState((prevState) => ({
        ...prevState,
        bodyPartsLoading: true,
      }));
      try {
        if (isAvatarCreated) {
          await this.handleUpdateAvatar(bodyFormData);
        } else {
          await this.handleCreateAvatar(bodyFormData);
        }
      } catch (e) {
        console.error(e)
      }
      this.setState((prevState) => ({
        ...prevState,
        bodyPartsLoading: false,
        isAvatarCreated: true,
      }));
    };

    private fetchPngBlob = (pngUrl: string): Promise<Blob> => {
      return fetch(pngUrl)
        .then((res) => res.blob())
        .catch((err) => {
          throw err;
        });
    };

    private prepareFormData = (pngBlob: Blob): FormData => {
      const properties = getPropertiesUrl();

      const bodyFormData = new FormData();
      bodyFormData.append("avatar", pngBlob, "avatar.png");

      for (let prop in properties) {
        bodyFormData.append(prop, properties[prop as keyof typeof properties]);
      }

      return bodyFormData;
    };

    private scanBarcode(message: string) {
      if (window) {
        if (window.Barcode && window.Barcode.postMessage) {
          window.Barcode.postMessage(message);
        }
      }
    }


    private handleUpdateAvatar = async (bodyFormData: FormData) => {
      try {
        const token = await this.getToken();

        if (!token || token.length < 1) return;
        const res = await updateAvatar(bodyFormData, token);

        if (res.status >= 200 && res.status < 300) {
          this.scanBarcode("success");
        } else {
          this.scanBarcode(`error ${res.status}`);
        }
      } catch (error) {
        this.scanBarcode(`error ${error}`);
      }
    };

    private handleCreateAvatar = async (bodyFormData: FormData) => {
      try {
        const token = await this.getToken();

        if (!token || token.length < 1) return;
        const res = await createAvatar(bodyFormData, token);

        if (res.status >= 200 && res.status < 300) {
          this.scanBarcode("success");
        } else {
          this.scanBarcode(`test ${res}`);
        }
      } catch (error) {
        this.scanBarcode(`error ${error}`);
      }
    };

    handleCurrentPart = (part: string) => {
      this.setState((prevState) => ({ ...prevState, currentPart: part }));
    };

     getToken = () => {

      if(this.token) {
        return this.token
      }

      return new Promise<string>((resolve, reject) => {
        setTimeout(() => {
          const glowyToken = localStorage.getItem("glowyToken");

          if (glowyToken && typeof glowyToken === "string") {
            this.token = glowyToken;
            resolve(glowyToken);
          } else {
            reject(new Error("Token not found or is not a string"));
          }
        }, 1000);
      });
    };

    getLocale = async () => {

      if(this.locale) {
        return this.locale
      }

      return new Promise<string>((resolve, reject) => {
        setTimeout(() => {
          const glowyLocale = localStorage.getItem("glowyLocale") || 'en';

          if (glowyLocale === 'ru') {
            this.locale = glowyLocale;
            resolve(glowyLocale);
          } else {
            this.locale =  'en';
            resolve('en');
          }
        }, 1000);
      });
    };
    private capitalizeFirstLetter(text: string) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }

    private onOptionValueChange = (key: string, value: string) => {

      const name = this.capitalizeFirstLetter(key);
      const handlerName = `onChange${name}`;

      const updateHandler = this.props[handlerName] as (value: string) => void;
      if(typeof updateHandler == 'function') {
        updateHandler(value);
      }

    };

    private getLocaleText =  (key: keyof ITranslationKeys) => {

      return getTranslate(key, this.state.locale) ;
    }

    private updateOptionContext(nextProps: any) {
      this.optionContext.setData(nextProps as any);
    }

    onCanvasRef = (ref: HTMLCanvasElement) => {
      this.canvasRef = ref;
    };

    render() {
      const {
        isAvatarCreated,
        token,
        currentPart,
        bodyPartsLoading,
        bodyPartTypes,
        storage,
      } = this.state;

      return (
        <WrappedComponent
          bodyPartsLoading={bodyPartsLoading}
          bodyPartTypes={bodyPartTypes}
          currentPart={currentPart}
          onRandom={this.onRandom}
          optionContext={this.optionContext}
          onAvatarRef={this.onAvatarRef}
          onDownloadPNG={this.onDownloadPNG}
          getLocaleText={this.getLocaleText}
          context={this}
          onCanvasRef={this.onCanvasRef}
          getToken={this.getToken}
          handleCurrentPart={this.handleCurrentPart}
          isAvatarCreated={isAvatarCreated}
          token={token}
          storage={storage}
          {...this.props}
        />
      );
    }
  };
};
