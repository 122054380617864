import React, { useState, useEffect } from "react";
import cn from "classnames";

import { colorsBg } from "../../utils/constants";

import classes from "./BackgroundSelector.module.scss";

interface IBackgroundSelectorProps {
  currentColor: string;
}

export const BackgroundSelector: React.FC<IBackgroundSelectorProps> = ({
  currentColor,
}) => {
  const [backgroundColor, setBackgroundColor] = useState<string>("#66C9FF");

  useEffect(() => {
    const svg = document.getElementById("Color/Palette/Blue-01");
    const svg2 = document.getElementById("Circle-Background");

    if (svg && svg2) {
      svg.style.fill = backgroundColor;
      svg2.style.fill = backgroundColor;
    }
  }, [backgroundColor]);

  const changeBackgroundColor = (color: string) => {
    setBackgroundColor(color);
  };

  const colorsBgMapped = colorsBg.filter(({ name }) => name !== currentColor);

  return (
    <div className={classes["background-selector"]}>
      {colorsBgMapped.map(({ name, color }) => (
        <button
          key={name}
          style={{ backgroundColor: color }}
          className={cn(
            classes["background-selector-part"],
            classes["background-selector-part-font"],
            backgroundColor === color &&
              classes["background-selector-part--active"]
          )}
          onClick={() => changeBackgroundColor(color)}
        />
      ))}
    </div>
  );
};
