import React from "react";
import ReactDOM from "react-dom";
import { configureUrlQuery } from "react-url-query";
import App from "./containers/App";
import history from "./history";

import "./index.css";


const rootElement = document.getElementById("root");

configureUrlQuery({ history });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  rootElement
);

if (process.env.NODE_ENV === 'development') {
    import('./reportWebVitals').then(({ default: reportWebVitals }) => {
        reportWebVitals(console.log);
    }).catch(error => {
        console.error('Error loading reportWebVitals:', error);
    });
}


